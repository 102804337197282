import { configureStore } from "@reduxjs/toolkit";
import { wnuApi } from "../api/wnuApi";
import { setupListeners } from "@reduxjs/toolkit/query";

import languageReducer from "../api/wnuApi";

export const store = configureStore({
  reducer: {
    [wnuApi.reducerPath]: wnuApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(wnuApi.middleware),
});

setupListeners(store.dispatch);
