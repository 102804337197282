import { styled } from "styled-components";
import arrow from "../../assets/icons/Arrow_Up_Right.svg";

const Button = styled.button`
  background-color: transparent;
  border-radius: 53px;
  border: 1px solid white;
  display: inline-flex;
  padding: 10px 50px 10px 26px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-transform: uppercase;
  color: ${props =>
    props.darkMode ? "var(--color-black)" : "var(--color-white)"};
  position: relative;
  font-size: 16px;
  font-weight: 400;

  &:before {
    content: "";
    background-image: url(${arrow});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 10;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 3%;
    transform: translate(-50%, -50%);

    filter: invert(${props => (props.darkMode ? "100%" : "0%")});
  }

  ${props =>
    props.darkMode &&
    `
    border-color: var(--color-black);
  `}
  @media screen and (max-width: 475px) {
    font-size: 16px;
  }
`;

export default Button;
