import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoLines from "../infoLines/InfoLines";
import styles from "./Lines.module.css";
import bgImages from "../../assets/images/sight_pic_big2_1730.png";
import { useNavigate } from "react-router-dom";
import { infoLines } from "../../data";
import Loader from "../loader/Loader";

const Lines = () => {
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleFilterData = () => {
    const data = infoLines.slice(0, 2);
    setData(data);
  };

  useEffect(handleFilterData, []);

  if (!data) {
    return <Loader />;
  }

  return (
    <div className={`${styles.lines_container} container`}>
      <h3>{t("home.line")}</h3>
      <hr />
      <div className={styles.lines_list}>
        {data?.map(item => (
          <div
            onClick={() => navigate(`/lines/info/${item.id}`)}
            className={styles.lines_card}>
            <img loading="lazy" src={item.mainPic} alt="" />
            <h3 onClick={() => navigate(`/lines/info/${item.id}`)}>
              {item[`title_${i18n.language}`]}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Lines;
