import firstImgEthno from "./assets/images/infolines/15.jpg";
import secondImgEthno from "./assets/images/infolines/16.jpg";
import thirdImgEthno from "./assets/images/infolines/17.jpg";
import forthImgEthno from "./assets/images/infolines/18.png";
import firstImgSport from "./assets/images/infolines/19.jpg";
import secondImgSport from "./assets/images/infolines/20.jpg";
import thirdImgSport from "./assets/images/infolines/21.jpg";
import forthImgSport from "./assets/images/infolines/22.jpeg";
import arroware from "./assets/images/Derece.jpg";

import ethnoCulture from "./assets/images/8.jpg";

import bgImages from "./assets/images/sight_pic_big2_1730.png";

export const infoLines = [
  {
    id: 1,
    title_ru: "этноспорт",
    title_en: "ethnosport",
    images: arroware,
    mainPic: bgImages,
    description_ru:
      "Этноспорт включает в себя традиционные и исконные виды спорта и игры различных культур.",
    description_en:
      "Ethnosports involve traditional and indigenous sports and games from various cultures.",
    details: {
      title_en: "Values of ethnosport",
      title_ru: "Ценности этноспорта",
      description_en: `In an era where mainstream sports prioritize industrialization and institutionalization, with success as the sole measure, Ethnosport stands as a beacon promoting cultural heritage and amicable competition. Through organizing ethnosport events and competitions, we aim to highlight the rich diversity of traditional sports.
      `,
      description_ru:
        "В эпоху, когда современные виды спорта ставят во главу угла индустриализацию и институционализацию, а единственным мерилом их успеха является достижение успеха, Этноспорт выступает в качестве маяка, пропагандирующего физическую форму, культурное наследие и дружеское соперничество. Организуя мероприятия и соревнования по этноспорту, мы стремимся подчеркнуть богатое разнообразие традиционных видов спорта. ",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
  {
    id: 2,
    title_ru: "этнокультура",
    title_en: "ethnoculture",
    images: arroware,
    mainPic: ethnoCulture,
    description_ru:
      "Этнокультура - это коллективная идентичность, обычаи, традиции, искусство, музыка, традиционные танцы, кухня, мода и ценности, разделяемые определенной культурной или этнической группой, которые определяют ее образ жизни и социальные нормы.",
    description_en:
      "Ethnoculture refers to the collective identity, customs, traditions, art, music, traditional dances, cuisine, fashion, and values shared by a particular cultural or ethnic group that shape its way of life and social norms.",
    details: {
      title_en: "Values of Ethnoculture",
      title_ru: "Ценности Этнокультуры",
      description_en: `Ethnoculture encompasses the unique practices and beliefs that distinguish one group from another, often passed down through generations and evolving over time through interactions and experiences within the community. Ethnoculture plays a fundamental role in shaping an individual's identity and fostering a sense of belonging within a cultural or ethnic community.
      `,
      description_ru:
        "Этнокультура включает в себя уникальные обычаи и верования, отличающие одну группу от другой, часто передающиеся из поколения в поколение и развивающиеся со временем благодаря взаимодействию и опыту внутри сообщества. Этнокультура играет основополагающую роль в формировании идентичности человека и развитии чувства принадлежности к культурному или этническому сообществу.",
      images: [firstImgEthno, secondImgEthno, thirdImgEthno, forthImgEthno],
    },
  },
  {
    id: 3,
    title_ru: "этноспорт",
    title_en: "ethnosport",
    images: arroware,
    mainPic: bgImages,
    description_ru:
      "Этноспорт включает в себя традиционные и исконные виды спорта и игры различных культур.",
    description_en:
      "Ethnosports involve traditional and indigenous sports and games from various cultures.",
    details: {
      title_en: "Values of ethnosport",
      title_ru: "Ценности этноспорта",
      description_en: `In an era where mainstream sports prioritize industrialization and institutionalization, with success as the sole measure, Ethnosport stands as a beacon promoting cultural heritage and amicable competition. Through organizing ethnosport events and competitions, we aim to highlight the rich diversity of traditional sports.
      `,
      description_ru:
        "В эпоху, когда современные виды спорта ставят во главу угла индустриализацию и институционализацию, а единственным мерилом их успеха является достижение успеха, Этноспорт выступает в качестве маяка, пропагандирующего физическую форму, культурное наследие и дружеское соперничество. Организуя мероприятия и соревнования по этноспорту, мы стремимся подчеркнуть богатое разнообразие традиционных видов спорта. ",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
  {
    id: 4,
    title_ru: "наука",
    title_en: "science",
    images: arroware,
    mainPic: ethnoCulture,
    description_ru:
      "Этнокультура - это коллективная идентичность, обычаи, традиции, искусство, музыка, традиционные танцы, кухня, мода и ценности, разделяемые определенной культурной или этнической группой, которые определяют ее образ жизни и социальные нормы.",
    description_en:
      "Ethnoculture refers to the collective identity, customs, traditions, art, music, traditional dances, cuisine, fashion, and values shared by a particular cultural or ethnic group that shape its way of life and social norms.",
    details: {
      title_en: "Values of Ethnoculture",
      title_ru: "Ценности Этнокультуры",
      description_en: `Ethnoculture encompasses the unique practices and beliefs that distinguish one group from another, often passed down through generations and evolving over time through interactions and experiences within the community. Ethnoculture plays a fundamental role in shaping an individual's identity and fostering a sense of belonging within a cultural or ethnic community.
      `,
      description_ru:
        "Этнокультура включает в себя уникальные обычаи и верования, отличающие одну группу от другой, часто передающиеся из поколения в поколение и развивающиеся со временем благодаря взаимодействию и опыту внутри сообщества. Этнокультура играет основополагающую роль в формировании идентичности человека и развитии чувства принадлежности к культурному или этническому сообществу.",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
  {
    id: 5,
    title_ru: "этноспорт",
    title_en: "ethnosport",
    images: arroware,
    mainPic: bgImages,
    description_ru:
      "Этноспорт включает в себя традиционные и исконные виды спорта и игры различных культур.",
    description_en:
      "Ethnosports involve traditional and indigenous sports and games from various cultures.",
    details: {
      title_en: "Values of ethnosport",
      title_ru: "Ценности этноспорта",
      description_en: `In an era where mainstream sports prioritize industrialization and institutionalization, with success as the sole measure, Ethnosport stands as a beacon promoting cultural heritage and amicable competition. Through organizing ethnosport events and competitions, we aim to highlight the rich diversity of traditional sports.
      `,
      description_ru:
        "В эпоху, когда современные виды спорта ставят во главу угла индустриализацию и институционализацию, а единственным мерилом их успеха является достижение успеха, Этноспорт выступает в качестве маяка, пропагандирующего физическую форму, культурное наследие и дружеское соперничество. Организуя мероприятия и соревнования по этноспорту, мы стремимся подчеркнуть богатое разнообразие традиционных видов спорта. ",
      images: [firstImgEthno, secondImgEthno, thirdImgEthno, forthImgEthno],
    },
  },
  {
    id: 6,
    title_ru: "этнокультура",
    title_en: "ethnoculture",
    images: arroware,
    mainPic: ethnoCulture,
    description_ru:
      "Этнокультура - это коллективная идентичность, обычаи, традиции, искусство, музыка, традиционные танцы, кухня, мода и ценности, разделяемые определенной культурной или этнической группой, которые определяют ее образ жизни и социальные нормы.",
    description_en:
      "Ethnoculture refers to the collective identity, customs, traditions, art, music, traditional dances, cuisine, fashion, and values shared by a particular cultural or ethnic group that shape its way of life and social norms.",
    details: {
      title_en: "Values of Ethnoculture",
      title_ru: "Ценности Этнокультуры",
      description_en: `Ethnoculture encompasses the unique practices and beliefs that distinguish one group from another, often passed down through generations and evolving over time through interactions and experiences within the community. Ethnoculture plays a fundamental role in shaping an individual's identity and fostering a sense of belonging within a cultural or ethnic community.
      `,
      description_ru:
        "Этнокультура включает в себя уникальные обычаи и верования, отличающие одну группу от другой, часто передающиеся из поколения в поколение и развивающиеся со временем благодаря взаимодействию и опыту внутри сообщества. Этнокультура играет основополагающую роль в формировании идентичности человека и развитии чувства принадлежности к культурному или этническому сообществу.",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
  {
    id: 7,
    title_ru: "наука",
    title_en: "science",
    images: arroware,
    mainPic: bgImages,
    description_ru:
      "Этноспорт включает в себя традиционные и исконные виды спорта и игры различных культур.",
    description_en:
      "Ethnosports involve traditional and indigenous sports and games from various cultures.",
    details: {
      title_en: "Values of ethnosport",
      title_ru: "Ценности этноспорта",
      description_en: `In an era where mainstream sports prioritize industrialization and institutionalization, with success as the sole measure, Ethnosport stands as a beacon promoting cultural heritage and amicable competition. Through organizing ethnosport events and competitions, we aim to highlight the rich diversity of traditional sports.
      `,
      description_ru:
        "В эпоху, когда современные виды спорта ставят во главу угла индустриализацию и институционализацию, а единственным мерилом их успеха является достижение успеха, Этноспорт выступает в качестве маяка, пропагандирующего физическую форму, культурное наследие и дружеское соперничество. Организуя мероприятия и соревнования по этноспорту, мы стремимся подчеркнуть богатое разнообразие традиционных видов спорта. ",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
  {
    id: 8,
    title_ru: "этнокультура",
    title_en: "ethnoculture",
    images: arroware,
    mainPic: ethnoCulture,
    description_ru:
      "Этнокультура - это коллективная идентичность, обычаи, традиции, искусство, музыка, традиционные танцы, кухня, мода и ценности, разделяемые определенной культурной или этнической группой, которые определяют ее образ жизни и социальные нормы.",
    description_en:
      "Ethnoculture refers to the collective identity, customs, traditions, art, music, traditional dances, cuisine, fashion, and values shared by a particular cultural or ethnic group that shape its way of life and social norms.",
    details: {
      title_en: "Values of Ethnoculture",
      title_ru: "Ценности Этнокультуры",
      description_en: `Ethnoculture encompasses the unique practices and beliefs that distinguish one group from another, often passed down through generations and evolving over time through interactions and experiences within the community. Ethnoculture plays a fundamental role in shaping an individual's identity and fostering a sense of belonging within a cultural or ethnic community.
      `,
      description_ru:
        "Этнокультура включает в себя уникальные обычаи и верования, отличающие одну группу от другой, часто передающиеся из поколения в поколение и развивающиеся со временем благодаря взаимодействию и опыту внутри сообщества. Этнокультура играет основополагающую роль в формировании идентичности человека и развитии чувства принадлежности к культурному или этническому сообществу.",
      images: [firstImgSport, secondImgSport, thirdImgSport, forthImgSport],
    },
  },
];

export const month = {
  "01": "Январь",
  "02": "Февраль",
  "03": "Март",
  "04": "Апрель",
  "05": "Май",
  "06": "Июнь",
  "07": "Июль",
  "08": "Август",
  "09": "Сентябрь",
  10: "Октябрь",
  11: "Ноябрь",
  12: "Декабрь",
};

export const monthEnglish = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};
