import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { endpoints } from "./apiEndpoints";
import { API_URL } from "../../constants";

export const wnuApi = createApi({
  reducerPath: "wnuApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: builder => ({
    // ! backgrounds start
    getBackground: builder.query({
      query: ({ id, language }) => `${language}/${endpoints.background}`,
    }),
    getOneBackground: builder.query({
      query: ({ id, language }) => `${language}/${endpoints.background}/${id}`,
    }),
    // ! backgrounds end
    // ! posts start
    getPosts: builder.query({
      query: ({ language }) => `${language}/${endpoints.blog}`,
    }),
    getOnePosts: builder.query({
      query: ({ id, language }) => `${language}/${endpoints.blog}/${id}`,
    }),
    // ! posts end
    // ! contacts start
    getContacts: builder.query({
      query: ({ language }) => `${language}/${endpoints.contactsGet}`,
    }),
    // ! contacts end
    // ! event start
    getEventFuture: builder.query({
      query: ({ language }) => `${language}/${endpoints.events.future}`,
    }),
    getOneEventFuture: builder.query({
      query: ({ id, language }) =>
        `${language}/${endpoints.events.future}/${id}`,
    }),
    getEventPosts: builder.query({
      query: ({ language }) => `${language}/${endpoints.events.pasts}`,
    }),
    getOneEventPost: builder.query({
      query: ({ id, language }) =>
        `${language}/${endpoints.events.pasts}/${id}`,
    }),
    // ! event end
    // ! mission start
    getMission: builder.query({
      query: ({ language }) => `${language}/${endpoints.mission}`,
    }),
    // ! mission end
    // ! mews start
    getNews: builder.query({
      query: ({ language }) => `${language}/${endpoints.news}`,
    }),
    getOneNews: builder.query({
      query: ({ id, language }) => `${language}/${endpoints.news}/${id}`,
    }),
    // ! news end
    // ! partners start
    getPartners: builder.query({
      query: ({ language }) => `${language}/${endpoints.partners}`,
    }),
    // ! partners end
    // ! service start
    getService: builder.query({
      query: ({ language }) => `${language}/${endpoints.service}`,
    }),
    getOneService: builder.query({
      query: ({ id, language }) => `${language}/${endpoints.service}/${id}`,
    }),
    // ! service end
    // ! team start
    getTeam: builder.query({
      query: ({ language }) => `${language}/${endpoints.team}`,
    }),
    // ! team end
    // ! email start
    postContact: builder.mutation({
      query: body => ({
        url: `en/${endpoints.contacts.email}/`,
        method: "POST",
        body,
      }),
    }),
    // ! email end
  }),
});

export const {
  useGetBackgroundQuery,
  useGetOneBackgroundQuery,
  useGetPostsQuery,
  useGetOnePostsQuery,
  useGetContactsQuery,
  useGetEventFutureQuery,
  useGetOneEventFutureQuery,
  useGetEventPostsQuery,
  useGetOneEventPostQuery,
  useGetMissionQuery,
  useGetNewsQuery,
  useGetOneNewsQuery,
  useGetPartnersQuery,
  useGetServiceQuery,
  useGetOneServiceQuery,
  useGetTeamQuery,
  usePostContactMutation,
} = wnuApi;
