import React from "react";
import { Route, Routes } from "react-router-dom";
import { pages } from "./routes.data";

const MainRoutes = () => {
  return (
    <Routes>
      {pages.map(page => (
        <Route path={page.path} element={page.component} key={page.id} />
      ))}
    </Routes>
  );
};

export default MainRoutes;
