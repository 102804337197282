import React, { useEffect, useState } from "react";

// import components
import TeamCard from "./TeamCard";

// import styles
import styles from "./Team.module.css";
import { useTranslation } from "react-i18next";
import { useGetTeamQuery } from "../../../redux/api/wnuApi";
import Loader from "../../../pages/loader/Loader";

const TeamList = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetTeamQuery({ language: i18n.language });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.team}>
      <div className="container">
        <div className={styles.team_header}>
          <h4>OUR TEAM</h4>
        </div>
        <div className={styles.employees_list}>
          {data?.map(employee => (
            <TeamCard key={employee.id} employee={employee} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamList;
