import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// import media
import picture from "../../../assets/images/news_card_picture.png";
import Loader from "../../../pages/loader/Loader";
import { useGetNewsQuery, useGetPostsQuery } from "../../../redux/api/wnuApi";
import Button from "../../../styles/button/Button";

// import styles
import styles from "./News.module.css";

const News = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetPostsQuery({ language: i18n.language });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.news}>
      <div className="container">
        <div className={styles.news_header}>
          <h5>{t("news.title")}</h5>
          <span onClick={() => navigate("/blog")}>{t("news.view")}</span>
        </div>
        <div className={styles.news_list}>
          {data?.slice(0, 4)?.map(news => (
            <div key={news.id} className={styles.news_card}>
              <img
                loading="lazy"
                onClick={() => navigate(`/blog/${news.id}`)}
                src={news.main_picture}
                alt={news.title}
              />
              <span
                onClick={() => navigate(`/blog/${news.id}`)}
                className={styles.news_date}>
                {news.date}
              </span>
              <p
                onClick={() => navigate(`/blog/${news.id}`)}
                className={styles.news_title}>
                {news.title}
              </p>
            </div>
          ))}
        </div>
        <div className={styles.news_redirect}>
          <Button
            onClick={() => navigate("/blog")}
            className={styles.new_button}
            darkMode={true}>
            {t("news.articles")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default News;
