import React from "react";
import { useMediaQuery } from "react-responsive";
import AboutHero from "../../componentes/about/hero/AboutHero";
import Mission from "../../componentes/about/mission/Mission";
import TeamList from "../../componentes/about/team/TeamList";
import Inquiries from "../../componentes/global/inquiries/Inquiries";
import Services from "../../componentes/global/services/Services";

const About = () => {
  return (
    <>
      <AboutHero />
      <Mission />
      <Services bgMode={true} />
      <TeamList />
    </>
  );
};

export default About;
