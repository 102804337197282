import React from "react";
import Inquiries from "../../componentes/global/inquiries/Inquiries";
import Services from "../../componentes/global/services/Services";

const ServicesPage = () => {
  return (
    <>
      <Services pageMode={true} />
      <Inquiries bgMode={true} />
    </>
  );
};

export default ServicesPage;
