import React from "react";
import Inquiries from "../../componentes/global/inquiries/Inquiries";
import Hero from "../../componentes/home/hero/Hero";
import News from "../../componentes/home/news/News";
import Purpose from "../../componentes/home/purpose/Purpose";
import Services from "../../componentes/global/services/Services";
import Animation from "../../componentes/home/animation/Animation";
import Header from "../../componentes/partners/header/Header";

import styles from "../../componentes/global/inquiries/Inquiries.module.css";
import { useGetPartnersQuery } from "../../redux/api/wnuApi";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetPartnersQuery({
    language: i18n.language,
  });

  return (
    <>
      <Animation />

      {/* <Hero /> */}
      <Purpose />
      <Services />
      <>
        <div className={styles.partners}>
          <div className={`container ${styles.header_container}`}>
            <h2>{t("partners.title")}</h2>
          </div>
          <div className="container">
            <div className={styles.inquires_marquee}>
              {data?.map(item => (
                <a target="_blank" href={item.link}>
                  <img loading="lazy" src={item.logo} alt="" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </>
      <News />
      {/* <Header /> */}
      <Inquiries />
    </>
  );
};

export default Home;
