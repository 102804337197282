import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { month, monthEnglish } from "../../../data";
import Loader from "../../../pages/loader/Loader";
import { useGetEventPostsQuery } from "../../../redux/api/wnuApi";

// import styles
import styles from "./PastEvent.module.css";

const PastEventPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetEventPostsQuery({
    language: i18n.language,
  });

  const handleFormatDate = (start, end) => {
    const dayStart = start?.split("-")[2];
    const dayEnd = end?.split("-")[2];

    const monthDate =
      i18n.language === "ru"
        ? month[start?.split("-")[1]]
        : monthEnglish[start?.split("-")[1]];

    const year = start?.split("-")[0];

    const date =
      i18n.language === "en"
        ? [dayStart, dayEnd, monthDate, year]
        : [monthDate, dayStart, dayEnd, year];

    return date;
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.past_event}>
      {data?.map(item => (
        <div key={item.id} className={styles.past_event_card}>
          <img
            loading="lazy"
            onClick={() => navigate(`/event/${item.id}`)}
            src={item.main_picture}
            alt={item.title}
          />
          <span>
            {handleFormatDate(item.start, item.end)[0]} -{" "}
            {handleFormatDate(item.start, item.end)[1]} /{" "}
            {handleFormatDate(item.start, item.end)[2]} /{" "}
            {handleFormatDate(item.start, item.end)[3]}
          </span>
          <h2 onClick={() => navigate(`/event/${item.id}`)}>{item.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default PastEventPage;
