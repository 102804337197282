import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../pages/loader/Loader";
import { useGetPostsQuery } from "../../../redux/api/wnuApi";
import styles from "./BlogDetails.module.css";

const ReadNext = () => {
  const [nextBlog, setNextBlog] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetPostsQuery({
    language: i18n.language,
  });

  const handleFilterData = id => {
    const nextData = data?.filter(item => item.id != id)?.slice(0, 3);
    setNextBlog(nextData);
  };

  useEffect(() => {
    handleFilterData(id);
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.next_page_wrapper}>
      {nextBlog?.map(item => (
        <div key={item.id} className={styles.next_page_card}>
          <img loading="lazy" src={item.main_picture} alt={item.title} />
          <p onClick={() => navigate(`/blog/${item.id}`)}>{item.date}</p>
          <h2 onClick={() => navigate(`/blog/${item.id}`)}>{item.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default ReadNext;
