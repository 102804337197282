import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ReactComponent as Facebook } from "../../../assets/icons/Facebook_gray.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/Linkedin_gray.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/Twitter_grey.svg";

// import styles
import styles from "./PastDetails.module.css";
import Calendar from "../calendar/Calendar";
import { useTranslation } from "react-i18next";
import {
  useGetOneEventFutureQuery,
  useGetOneEventPostQuery,
} from "../../../redux/api/wnuApi";
import Loader from "../../../pages/loader/Loader";
import { month, monthEnglish } from "../../../data";

const PastDetails = () => {
  const { pathname } = useLocation();

  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const eventQueryHook = pathname.includes("future")
    ? useGetOneEventFutureQuery
    : useGetOneEventPostQuery;

  const { data, isLoading } = eventQueryHook({
    id,
    language: i18n.language,
  });

  const handleFormatDate = (start, end) => {
    const dayStart = start?.split("-")[2];
    const dayEnd = end?.split("-")[2];

    const monthDate =
      i18n.language === "ru"
        ? month[start?.split("-")[1]]
        : monthEnglish[start?.split("-")[1]];

    const year = start?.split("-")[0];

    const date =
      i18n.language === "en"
        ? [dayStart, dayEnd, monthDate, year]
        : [monthDate, dayStart, dayEnd, year];

    return date;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`container ${styles.past_details}`}>
      <div className={styles.past_details_header}>
        <div className={styles.header_content}>
          <h1>{data.title}</h1>
          <p>
            <span>
              {handleFormatDate(data.start, data.end)[0]} -{" "}
              {handleFormatDate(data.start, data.end)[1]}
            </span>
            /
            <span>
              {handleFormatDate(data.start, data.end)[2]} /{"   "}
              {handleFormatDate(data.start, data.end)[3]}
            </span>
          </p>
        </div>
        <div className={styles.share}>
          <p>{t("event.share")}</p>
          <Facebook />
          <Linkedin />
          <Twitter />
        </div>
      </div>
      <hr className={styles.header_line} />
      <div className={styles.about_details}>
        <div className={styles.about_detail_left}>
          <h3>{t("event.about")}</h3>
          <p>{data.description}</p>

          <div className={styles.about_details_table}>
            <hr />
            <div className={styles.table_row}>
              <h4>{t("event.year")}</h4>
              <p>{data.year}</p>
            </div>
            <hr />
            <div className={styles.table_row}>
              <h4>{t("event.location")}</h4>
              <p>{data.location}</p>
            </div>
            <hr />
            <div className={styles.table_row}>
              <h4>{t("event.international")}</h4>
              <p>{data.athletes}</p>
            </div>
            <hr />
          </div>
        </div>
        <img loading="lazy" src={data.main_picture} alt="" />
      </div>
      <div className={styles.past_images}>
        <img loading="lazy" src={data.picture_1} alt="" />
        <img loading="lazy" src={data.picture_2} alt="" />
        <img loading="lazy" src={data.picture_3} alt="" />
        <img loading="lazy" src={data.picture_4} alt="" />
        <img loading="lazy" src={data.picture_5} alt="" />
        <img loading="lazy" src={data.picture_6} alt="" />
        <img loading="lazy" src={data.picture_7} alt="" />
      </div>

      <div className={styles.other_events}>
        <h3>{t("event.other")}</h3>
        <hr />
      </div>
      <Calendar />
    </div>
  );
};

export default PastDetails;
