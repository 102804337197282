import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import BurgerMenu from "./componentes/global/BurgerMenu/BurgerMenu";
import Footer from "./componentes/global/Footer/Footer";
import Inquiries from "./componentes/global/inquiries/Inquiries";

// import components
import Navbar from "./componentes/global/Navbar/Navbar";
import NavbarDefault from "./componentes/global/NavbarDefault/NavbarDefault";
import Animation from "./componentes/home/animation/Animation";

// import styles
import "./global.css";
import ScrollToTop from "./helpers/Components";
import Loader from "./pages/loader/Loader";
import MainRoutes from "./Routes/MainRoutes";

const App = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showNavbarDefault, setShowNavbarDefault] = useState(false);

  const inMobile = useMediaQuery({ maxWidth: 767 });

  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 650 && pathname === "/") {
        setShowNavbar(false);
        setShowNavbarDefault(true);
      } else {
        setShowNavbar(true);
        setShowNavbarDefault(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.setAttribute("lang", i18n.language);
    return () => {
      document.body.removeAttribute("lang");
    };
  }, [i18n.language]);

  return (
    <>
      <ScrollToTop />
      {showNavbar && <Navbar />}
      {showNavbarDefault && <NavbarDefault />}

      <MainRoutes />
      {inMobile &&
        pathname !== "/" &&
        pathname !== "/contacts" &&
        pathname !== "/services" && (
          <Inquiries
            bgMode={
              pathname === "/about" || pathname === /lines/ ? true : false
            }
          />
        )}
      <Footer />
    </>
  );
};

export default App;
