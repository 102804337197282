import React from "react";
import { useNavigate } from "react-router-dom";

// import media
import { ReactComponent as NotFoundIcon } from "../../assets/icons/not_found.svg";
import Button from "../../styles/button/Button";

// import styles
import styles from "./NotFound.module.css";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.not_found}>
      <div className={styles.not_found_container}>
        <NotFoundIcon />
        <h2>Something’s missing.</h2>
        <p>The page you looking for is not found.</p>
        <Button darkMode={true} onClick={() => navigate("/")}>
          go to homepage
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
