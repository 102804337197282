import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enJson from "../jsons/en.json";
import ruJson from "../jsons/ru.json";

const languageLS = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJson,
    },
    ru: {
      translation: ruJson,
    },
  },
  lng: languageLS || "en",
  fallback: languageLS || "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

export const currentLanguage = i18n.language;
