import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

// import media
import { ReactComponent as Facebook } from "../../../assets/icons/Facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/Instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/Youtube.svg";

import languageIcon from "../../../assets/icons/language-white.png";

// import styles
import "./BurgerMenu.css";
import styles from "./BurgerMenu.module.css";

const BurgerMenu = () => {
  const { t, i18n } = useTranslation();
  const [modalChange, setModalChange] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(i18n.language);

  const dropdownRef = useRef(null);
  const menuIconRef = useRef(null);
  const menuWrapperRef = useRef(null);

  const navigate = useNavigate();

  const options = ["en", "ru"];

  const toggleSelect = event => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    setSelectedOption(option);
    localStorage.setItem("language", option);

    setIsOpen(false);
  };

  const handleOutsideClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleToggleBurgerMenu = () => {
    setModalChange(!modalChange);

    menuIconRef.current.classList.toggle("menu-icon-active");
  };

  const handleCloseBurgerMenu = event => {
    if (menuWrapperRef.current.contains(event.target)) {
      setModalChange(false);
      menuIconRef.current.classList.toggle("menu-icon-active");
    }
  };

  useEffect(() => {
    const languageToLS = localStorage.getItem("language");
    languageToLS == null && localStorage.setItem("language", selectedOption);

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("click", handleCloseBurgerMenu);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("click", handleCloseBurgerMenu);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("language", selectedOption);
    const languageToLS = localStorage.getItem("language");
    i18n.changeLanguage(languageToLS);
  }, [selectedOption]);

  return (
    <div className="burger_menu_wrapper">
      <div className="menu-icon-wrapper" onClick={handleToggleBurgerMenu}>
        <div ref={menuIconRef} className="menu-icon"></div>
      </div>
      <div
        onClick={handleToggleBurgerMenu}
        className={`${styles.overlay} ${modalChange && styles.active} `}></div>
      <div
        ref={menuWrapperRef}
        className={`${styles.burger_container} ${
          modalChange && styles.burger_container_active
        }`}>
        <div className={styles.top}>
          <ul className={styles.top_content}>
            <li onClick={() => navigate("/about")}>{t("navbar.about")}</li>
            <li onClick={() => navigate("/services")}>
              {t("navbar.services")}
            </li>
            <li onClick={() => navigate("/blog")}>{t("navbar.blog")}</li>
            <li onClick={() => navigate("/event")}>{t("navbar.event")}</li>
            <li onClick={() => navigate("/contacts")}>
              {t("navbar.contacts")}
            </li>
            <div className={styles.select} ref={dropdownRef}>
              <div onClick={toggleSelect}>
                <img
                  loading="lazy"
                  className={styles.language_icon}
                  src={languageIcon}
                  width="24"
                  alt=""
                />
              </div>
              {isOpen && (
                <ul className={styles.select_dropDown}>
                  {options?.map((option, index) => (
                    <li key={index} onClick={() => handleOptionClick(option)}>
                      {option === "en" ? "English" : "Русский"}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ul>
        </div>
        {/* <div className={styles.bottom}>
          <Facebook />
          <Instagram />
          <Youtube />
        </div> */}
      </div>
    </div>
  );
};

export default BurgerMenu;
