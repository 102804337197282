import React from "react";
import { useTranslation } from "react-i18next";

// import styles
import styles from "./Hero.module.css";

const AboutHero = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.hero}>
      <div className={styles.hero_container}>
        <div className="container">
          <div className={styles.hero_content}>
            <h2>{t("about.title")}</h2>
            <p>{t("about.description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
