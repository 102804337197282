export const endpoints = {
  background: "background",
  blog: "blog",
  contactsGet: "contacts",
  events: {
    future: "events/future",
    pasts: "events/past",
  },
  mission: "mission",
  news: "news",
  partners: "partners",
  service: "service",
  team: "team",
  contacts: {
    contact: "contacts/send",
    email: "email/send",
  },
};
