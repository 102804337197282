import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import bgImages from "../../assets/images/sight_pic_big2_1730.png";
import Inquiries from "../../componentes/global/inquiries/Inquiries";
import InfoLinesContent from "../../componentes/infoLines/InfoLinesContent";
import { infoLines } from "../../data";
import Loader from "../loader/Loader";

import styles from "./InfoLines.module.css";

const InfoLines = () => {
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const handleFilterData = () => {
    const [data] = infoLines.filter(item => item.id == id);
    setData(data);
  };

  useEffect(handleFilterData, []);

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <div
        style={{ backgroundImage: `url(${data.mainPic})` }}
        className={styles.hero}>
        <div className={styles.hero_container}>
          <div className="container">
            <div className={styles.hero_content}>
              <h2>{data[`title_${i18n.language}`]}</h2>
              <p>{data[`description_${i18n.language}`]}</p>
            </div>
          </div>
        </div>
      </div>
      <InfoLinesContent data={data} />
    </>
  );
};

export default InfoLines;
