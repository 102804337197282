import React from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../../pages/loader/Loader";
import { useGetPostsQuery } from "../../../redux/api/wnuApi";
import BlogCard from "./BlogCard";

// import styles
import styles from "./BlogCard.module.css";

const BlogList = () => {
  const { t, i18n } = useTranslation();

  const { data, isLoading } = useGetPostsQuery({ language: i18n.language });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={`container ${styles.blog_list_wrapper}`}>
        <div className={styles.blog_header}>
          <h2>{t("blog.title")}</h2>
          <hr />
        </div>
        <div className={styles.blog_list}>
          {data?.map(item => (
            <BlogCard data={item} key={item.id} />
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogList;
