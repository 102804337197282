import React from "react";
import { useTranslation } from "react-i18next";

// import media

import caravan from "../../../assets/images/purpose_right.png";
import rectangle from "../../../assets/images/Rectangle.png";
import Loader from "../../../pages/loader/Loader";
import { useGetMissionQuery } from "../../../redux/api/wnuApi";

// import styles
import styles from "./Purpose.module.css";

const Purpose = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetMissionQuery({
    language: i18n.language,
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.purpose}>
      <img loading="lazy" className={styles.rectangle} src={rectangle} alt="" />
      <div className="container">
        <div className={styles.purpose_top_wrapper}>
          <div className={styles.purpose_top_left}>
            <h2>{t("mission.title")}</h2>
            <hr />
            <p>{t("mission.description")}</p>
          </div>
          <div className={styles.purpose_top_right}>
            <img loading="lazy" src={caravan} alt="purpose caravan" />
          </div>
        </div>
        <hr className={styles.between_line} />
        <div className={styles.purpose_bottom_wrapper}>
          <div className={styles.purpose_bottom_left}>
            {/* Нужно юзать map */}
            <div className={styles.purpose_bottom_item}>
              <h3>{t("mission.title_second")}</h3>
              <span className={styles.purpose_bottom_item_content}>
                {t("mission.description_second")}
              </span>
            </div>
            <hr />
            {data?.map(item => (
              <React.Fragment key={item.id}>
                <div className={styles.purpose_bottom_item}>
                  <p>{item.title}</p>
                  <span>{item.description}</span>
                </div>
                <hr />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purpose;
