import React from "react";
import ContactsHeader from "../../componentes/contacts/header/ContactsHeader";
import Inquiries from "../../componentes/global/inquiries/Inquiries";

const Contacts = () => {
  return (
    <>
      <ContactsHeader />
      <Inquiries />
    </>
  );
};

export default Contacts;
