import React, { useEffect, useState } from "react";
import styles from "./ContactsHeader.module.css";
import contactsRight from "../../../assets/images/contacts.png";
import { useTranslation } from "react-i18next";
import { useGetContactsQuery } from "../../../redux/api/wnuApi";
import Loader from "../../../pages/loader/Loader";

const ContactsHeader = () => {
  const [dataArray, setDataArray] = useState(null);
  const { t, i18n } = useTranslation();

  const { data, isLoading } = useGetContactsQuery({
    language: i18n.language,
  });

  if (!data && isLoading) {
    return <Loader />;
  }

  const combinedObject = data.reduce((result, obj) => {
    Object.keys(obj).forEach(key => {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(obj[key]);
    });
    return result;
  }, {});

  return (
    <div className={`container ${styles.contacts}`}>
      <div className={styles.contacts_header}>
        <h2>{t("contacts.title")}</h2>
        <span>{t("contacts.description")}</span>
      </div>
      <hr />
      <div className={styles.contacts_wrapper}>
        <div className={styles.contacts_table}>
          <div className={styles.contacts_table_row}>
            <p className={styles.table_title}>{t("contacts.address")}</p>
            <div>
              {combinedObject?.office_address?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
          <hr />
          <div className={styles.contacts_table_row}>
            <p className={styles.table_title}>{t("contacts.number")}</p>
            <div>
              {combinedObject?.phone_number?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
          <hr />
          <div className={styles.contacts_table_row}>
            <p className={styles.table_title}>{t("contacts.email")}</p>
            <div>
              {combinedObject?.email?.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
          <hr />
        </div>
        <img loading="lazy" src={contactsRight} alt="" />
      </div>
    </div>
  );
};

export default ContactsHeader;
