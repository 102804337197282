import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/icons/wnu_logo.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/down-arrow-svgrepo-com.svg";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import styles from "../Navbar/Navbar.module.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../language/settings/i18n";
import { changeLanguageState, wnuApi } from "../../../redux/api/wnuApi";
import { useDispatch } from "react-redux";
import languageIcon from "../../../assets/icons/language.png";
import { useMediaQuery } from "react-responsive";

const NavbarDefault = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(i18n.language);

  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const inMobile = useMediaQuery({ maxWidth: 850 });

  const options = ["en", "ru"];

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleOutsideClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const languageToLS = localStorage.getItem("language");
    languageToLS == null && localStorage.setItem("language", selectedOption);

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("language", selectedOption);
    const languageToLS = localStorage.getItem("language");
    i18n.changeLanguage(languageToLS);
  }, [selectedOption]);

  return (
    <>
      <div className={`${styles.header_default}`}>
        <div className="container">
          <div className={`${styles.nav}`}>
            <button onClick={() => navigate("/")}>
              <Logo className={styles.logo} />
            </button>
            <div className={`${styles.left_block}`}>
              {inMobile ? (
                <BurgerMenu />
              ) : (
                <ul className={styles.left_block_content}>
                  <li onClick={() => navigate("/about")}>
                    {t("navbar.about")}
                  </li>
                  <li onClick={() => navigate("/services")}>
                    {t("navbar.services")}
                  </li>
                  <li onClick={() => navigate("/blog")}>{t("navbar.blog")}</li>
                  <li onClick={() => navigate("/event")}>
                    {t("navbar.event")}
                  </li>
                  {/* <li onClick={() => navigate("/partners")}>Partners</li> */}
                  <li onClick={() => navigate("/contacts")}>
                    {t("navbar.contacts")}
                  </li>
                  <div className={styles.select} ref={dropdownRef}>
                    <div onClick={toggleSelect}>
                      {/* {selectedOption === "en" ? "English" : "Русский"} */}
                      <img
                        loading="lazy"
                        src={languageIcon}
                        width="24"
                        alt=""
                      />
                      {/* <Arrow
                      className={
                        isOpen
                          ? `${styles.select_open}`
                          : `${styles.select_close}`
                      }
                    /> */}
                    </div>
                    {isOpen && (
                      <ul className={styles.select_dropDown}>
                        {options?.map(
                          (option, index) =>
                            selectedOption !== option && (
                              <li
                                key={index}
                                onClick={() => handleOptionClick(option)}>
                                {option === "en" ? "English" : "Русский"}
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  </div>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarDefault;
