import React, { useEffect, useState } from "react";
// import media
import { ReactComponent as Add } from "../../../assets/icons/Add_icon.svg";
import { ReactComponent as AddBlack } from "../../../assets/icons/Add_icon_black.svg";
import rectangle from "../../../assets/images/Rectangle.png";

// import styles
import Button from "../../../styles/button/Button";
import styles from "./Services.module.css";
import { useTranslation } from "react-i18next";
import {
  useGetBackgroundQuery,
  useGetOneBackgroundQuery,
  useGetOneServiceQuery,
  useGetServiceQuery,
} from "../../../redux/api/wnuApi";
import Loader from "../../../pages/loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Services = ({ bgMode, pageMode }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const eventQueryHook = bgMode
    ? [useGetBackgroundQuery, useGetOneBackgroundQuery]
    : [useGetServiceQuery, useGetOneServiceQuery];

  const [DataService, OneDataService] = eventQueryHook;

  const { data, isLoading } = DataService({ language: i18n.language });
  const { data: details, isLoading: isLoadingDetails } = OneDataService({
    id: activeAccordion,
    language: i18n.language,
  });

  const handleAccordionClick = (id, index) => {
    setActiveAccordion(prevActive => (prevActive === id ? null : id || index));
  };

  if (isLoading && isLoadingDetails) {
    return <Loader />;
  }

  return (
    <div className={`${bgMode ? styles.services_bg : styles.services}`}>
      {bgMode ? (
        <img
          loading="lazy"
          className={styles.rectangle}
          src={rectangle}
          alt=""
        />
      ) : (
        ""
      )}
      <div className="container">
        {pathname === "/about" ? (
          <h4>{t("background.title")}</h4>
        ) : (
          <h4>{t("services.title")}</h4>
        )}
        <p className={pageMode ? styles.page_text : styles.page_none}>
          {t("services.description")}
        </p>
        <hr className={`${bgMode ? styles.services_line : ""}`} />
        <div className={styles.accordion}>
          {data?.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className={`${styles.accordion_item} ${
                  activeAccordion === item.id && styles.accordion_active
                }`}>
                <div
                  className={`${
                    bgMode
                      ? styles.accordion_content
                      : styles.accordion_content_none
                  }`}>
                  <span className={bgMode ? styles.years : styles.number}>
                    {bgMode ? item.year : 0 + index + 1}
                  </span>
                  <div
                    className={styles.info}
                    onClick={() => handleAccordionClick(item.id, index)}>
                    <p>{item.title}</p>
                    {bgMode ? <AddBlack /> : <Add />}
                  </div>
                </div>

                {bgMode && activeAccordion === item.id ? (
                  <div className={styles.accordion_details}>
                    <ul>
                      {details?.paragraphs?.map(item => (
                        <li key={item.id}>{item.paragraph}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  details &&
                  activeAccordion === item.id && (
                    <div className={styles.accordion_details}>
                      <span className={styles.accordion_content}>
                        {details.description}
                      </span>

                      <img loading="lazy" src={details.picture} alt="" />
                    </div>
                  )
                )}
              </div>
              <hr className={`${bgMode ? styles.services_line : ""}`} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
