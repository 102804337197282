import React from "react";

// import media
import { ReactComponent as Logo } from "../../../assets/icons/wnu_logo.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/Facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/Instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/Youtube.svg";

// import styles
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import PolicyEn from "../../../assets/politic/PrivacyPolicyWNU.pdf";
import PolicyRu from "../../../assets/politic/PrivacyPolicyRuWNU.pdf";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const switchPolicy = () => {
    if (i18n.language === "en") {
      window.open(PolicyEn, "_blank");
    } else {
      window.open(PolicyRu, "_blank");
    }
  };

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.footer_general_wrapper}>
          <div className={styles.footer_left_main_wrapper}>
            <div className={styles.footer_left}>
              <Logo />
              <span onClick={switchPolicy}>
                © {currentYear} World Nomad Union. All rights reserved
              </span>
            </div>
            <div className={styles.footer_center}>
              <ul>
                <li onClick={() => navigate("/about")}>{t("navbar.about")}</li>
                <li onClick={() => navigate("/services")}>
                  {t("navbar.services")}
                </li>
                <li onClick={() => navigate("/blog")}>{t("navbar.blog")}</li>
              </ul>
              <ul>
                <li onClick={() => navigate("/contacts")}>
                  {t("navbar.contacts")}
                </li>
                <li onClick={() => navigate("/event")}>{t("navbar.event")}</li>
              </ul>
            </div>
          </div>
          <div className={styles.footer_right}>
            {/* <Facebook style={{ cursor: "pointer" }} />
            <Instagram style={{ cursor: "pointer" }} />
            <Youtube style={{ cursor: "pointer" }} /> */}
          </div>
          <span onClick={switchPolicy} className={styles.footer_mobile_span}>
            © {currentYear} World Nomad Union. All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
