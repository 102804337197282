// import hooks
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import data
// import media
import { ReactComponent as Facebook } from "../../../assets/icons/Facebook_gray.svg";
import { ReactComponent as Linkedin } from "../../../assets/icons/Linkedin_gray.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/Twitter_grey.svg";
import image from "../../../assets/images/about_hero_bg.png";

// import styles
import styles from "./BlogDetails.module.css";
import { useGetOnePostsQuery } from "../../../redux/api/wnuApi";
import Loader from "../../../pages/loader/Loader";
import ReadNext from "./ReadNext";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const BlogDetails = () => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const { data, isLoading } = useGetOnePostsQuery({
    id,
    language: i18n.language,
  });

  if (isLoading) {
    return <Loader />;
  }

  console.log(data.description);

  const currentPageUrl = window.location.href;

  return (
    data && (
      <div className={`container ${styles.blog_details}`}>
        <div className={styles.blog_details_header}>
          <div className={styles.blog_details_content}>
            <h2>{data.title}</h2>
            <p className={styles.date}>{data.date}</p>
          </div>

          <div className={styles.share}>
            <p>{t("event.share")}</p>
            <FacebookShareButton url={currentPageUrl}>
              <Facebook />
            </FacebookShareButton>
            <LinkedinShareButton url={currentPageUrl}>
              <Linkedin />
            </LinkedinShareButton>
            <TwitterShareButton url={currentPageUrl}>
              <Twitter />
            </TwitterShareButton>
          </div>
        </div>
        <hr />
        <img loading="lazy" src={data.main_picture} alt={data.title} />
        <div className={styles.blog_details_description}>
          <p dangerouslySetInnerHTML={{ __html: data.description }}></p>

          {data.source && (
            <span className={styles.source}>
              {t("blog.source")}:{" "}
              <a href={data.source} target="_blank">
                {data.source}
              </a>
            </span>
          )}
        </div>
        <div className={styles.main_details_img}>
          <img
            loading="lazy"
            className={styles.images}
            src={data.picture_1}
            alt=""
          />
          <img
            loading="lazy"
            className={styles.images}
            src={data.picture_2}
            alt=""
          />
        </div>

        <div className={styles.next_page}>
          <h2>{t("blog.next")}</h2>
          <ReadNext />
        </div>
      </div>
    )
  );
};

export default BlogDetails;
