import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { infoLines } from "../../../data";
import Navbar from "../../global/Navbar/Navbar";
import horse from "../../../assets/images/horse.png";
import "./Animation.scss";
import i18n from "../../../language/settings/i18n";

const Animation = () => {
  const [mouseMove, setMouseMove] = useState(null);

  const { t } = useTranslation();

  const inLink = useMediaQuery({ maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navigate = useNavigate();

  const handleMouseMove = id => {
    if (isMobile) {
      return;
    }
    setMouseMove(mouseMove === id ? null : id);
  };

  return (
    <>
      <div className="homer_page_animation">
        {!inLink && (
          <div className="home_horse">
            <img loading="lazy" src={horse} alt="" />
          </div>
        )}
        <div className="container">
          <div className="animation_left">
            {/* <p>{t("home.welcome")}</p> */}
            <h1>{t("home.title")}</h1>
            {/* <p>{t("home.partners")}</p> */}
            <span className="animation_left_spans">{t("home.festival")}</span>
            {inLink && (
              <span
                onClick={() => navigate("/lines/all")}
                className="diraction_span animation_left_link">
                {t("home.line")}
              </span>
            )}
          </div>
          <div className="animation_wrapper">
            <div className="main_block">
              {infoLines?.map((item, index) => (
                <div
                  key={item.id}
                  onMouseEnter={() => !isMobile && handleMouseMove(item.id)}
                  onMouseLeave={() => !isMobile && handleMouseMove(item.id)}
                  onClick={() => navigate(`/lines/info/${item.id}`)}
                  style={{
                    animationPlayState: mouseMove && "paused",
                    backgroundImage:
                      mouseMove === item.id ? `url(${item.mainPic})` : "",
                    backdropFilter: "brightness(0)",
                    pointerEvents: inLink && "none",
                  }}
                  className={`blocks block${
                    index + 1
                  } animation_content_wrapper`}>
                  {!isMobile && <span>{item[`title_${i18n.language}`]}</span>}
                </div>
              ))}

              {[
                9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
              ].map(item => (
                <div key={item} className={`block block${item}`}></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Animation;
