import BlogDetails from "../componentes/blog/blogDetails/BlogDetails";
import PastDetails from "../componentes/event/pastDetails/PastDetails";
import About from "../pages/about/About";
import Blog from "../pages/blog/Blog";
import Contacts from "../pages/contacts/Contacts";
import EventCalendarPage from "../pages/events/eventCalendar/EventCalendarPage";
import Home from "../pages/home/Home";
import InfoLines from "../pages/infoLines/InfoLines";
import Lines from "../pages/lines/Lines";
import NotFound from "../pages/notFound/NotFound";
import Partners from "../pages/partners/Partners";
import ServicesPage from "../pages/services/ServicesPage";

export const pages = [
  { path: "/", component: <Home />, id: 1 },
  { path: "/about", component: <About />, id: 2 },
  { path: "/services", component: <ServicesPage />, id: 3 },
  { path: "/services/:id", component: <ServicesPage />, id: 13 },

  { path: "/*", component: <NotFound />, id: 4 },
  { path: "/blog", component: <Blog />, id: 5 },
  { path: "/blog/:id", component: <BlogDetails />, id: 6 },
  { path: "/event", component: <EventCalendarPage />, id: 7 },
  { path: "/event/:id", component: <PastDetails />, id: 8 },
  { path: "/event/future/:id", component: <PastDetails />, id: 12 },

  // { path: "/partners", component: <Partners />, id: 9 },
  { path: "/contacts", component: <Contacts />, id: 10 },
  { path: "/lines/info/:id", component: <InfoLines />, id: 11 },
  { path: "/lines/all", component: <Lines />, id: 14 },
];
