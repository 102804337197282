import React from "react";
import { useTranslation } from "react-i18next";

import employees from "../../assets/images/employees.png";

import styles from "./InfoLinesContent.module.css";

const InfoLinesContent = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`container ${styles.infoLines_container}`}
      style={{ marginBottom: "32px" }}>
      <div className={styles.infoLines_header}>
        <h2>{data.details[`title_${i18n.language}`]}</h2>
        <p>{data.details[`description_${i18n.language}`]}</p>
      </div>
      <div className={styles.about_images}>
        {data.details.images.map(item => (
          <img loading="lazy" key={item} src={item} alt="" />
        ))}
      </div>
    </div>
  );
};

export default InfoLinesContent;
