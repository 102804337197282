import React from "react";
import { useNavigate } from "react-router-dom";

// import styles
import styles from "./BlogCard.module.css";

const BlogCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.blog_card}>
      <img
        loading="lazy"
        onClick={() => navigate(`/blog/${data.id}`)}
        src={data.main_picture}
        alt={data.title}
      />
      <p onClick={() => navigate(`/blog/${data.id}`)}>{data.date}</p>
      <h2 onClick={() => navigate(`/blog/${data.id}`)}>{data.title}</h2>
    </div>
  );
};

export default BlogCard;
