import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { month, monthEnglish } from "../../../data";
import Loader from "../../../pages/loader/Loader";
import { useGetEventFutureQuery } from "../../../redux/api/wnuApi";
import Button from "../../../styles/button/Button";

// import styles
import styles from "./Calendar.module.css";

const Calendar = () => {
  const [currentData, setCurrentData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetEventFutureQuery({
    language: i18n.language,
  });

  const handleFilterData = () => {
    const filteredData = data?.filter(item => item.id != id);
    setCurrentData(filteredData);
  };

  const handleFormatDate = (start, end) => {
    const dayStart = start?.split("-")[2];
    const dayEnd = end?.split("-")[2];

    const monthDate =
      i18n.language === "ru"
        ? month[start?.split("-")[1]]
        : monthEnglish[start?.split("-")[1]];

    const year = start?.split("-")[0];

    const date = [dayStart, dayEnd, monthDate, year];

    return date;
  };

  useEffect(() => {
    handleFilterData();
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.calendar}>
      {currentData?.map(item => (
        <React.Fragment key={item.id}>
          <div className={styles.card}>
            <div className={styles.data}>
              <div className={styles.date}>
                <span>
                  {handleFormatDate(item.start, item.end)[0]} -{" "}
                  {handleFormatDate(item.start, item.end)[1]}
                </span>
                <p>
                  {handleFormatDate(item.start, item.end)[2]} -{" "}
                  {handleFormatDate(item.start, item.end)[3]}
                </p>
              </div>
              <div className={styles.content}>
                <h2>{item.title}</h2>
                <Button
                  onClick={() => navigate(`/event/future/${item.id}`)}
                  darkMode={true}>
                  Learn more
                </Button>
              </div>
            </div>
            <img loading="lazy" src={item.main_picture} alt="" />
          </div>
          <hr />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Calendar;
