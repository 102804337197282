import React from "react";

// import styles
import styles from "./Team.module.css";

const TeamCard = ({ employee }) => {
  return (
    <div className={styles.employees}>
      <img loading="lazy" src={employee.photo} alt={employee.name} />
      {/* <div className={styles.employees_pic}>
      </div> */}
      <p>{employee.name}</p>
      <span>{employee.position}</span>
    </div>
  );
};

export default TeamCard;
