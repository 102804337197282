import React from "react";
import { useTranslation } from "react-i18next";
import Calendar from "../../../componentes/event/calendar/Calendar";
import PastEventPage from "../../../componentes/event/pastEvent/PastEventPage";
import { useGetEventFutureQuery } from "../../../redux/api/wnuApi";
import Button from "../../../styles/button/Button";
import Loader from "../../loader/Loader";

// import styles
import styles from "./EventCalendarPage.module.css";

const EventCalendarPage = () => {
  const { t } = useTranslation();
  return (
    <div className={`container ${styles.calendar_page}`}>
      <h2 className={styles.event_header}>{t("event.calendar")}</h2>
      <hr className={styles.header_line} />
      <Calendar />
      <div className={styles.past_event_header}>
        <h2 className={styles.event_header}>{t("event.past")}</h2>
        <span>{t("event.view")}</span>
      </div>

      <PastEventPage />

      <Button darkMode={true}>{t("event.all")}</Button>
    </div>
  );
};

export default EventCalendarPage;
