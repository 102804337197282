import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// import media
import inquiriesHorse from "../../../assets/images/inquiries.png";
import Loader from "../../../pages/loader/Loader";
import {
  useGetPartnersQuery,
  usePostContactMutation,
} from "../../../redux/api/wnuApi";
// import styles
import styles from "./Inquiries.module.css";

import checkBox from "../../../assets/icons/checkbox.svg";
import checkBoxBlack from "../../../assets/icons/checkboxBlack.svg";

const Inquiries = ({ bgMode }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [check, setCheck] = useState(false);

  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();

  const [postData, { isLoading, isError, error }] = usePostContactMutation();

  if (isLoading) {
    return <Loader />;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (!check) {
      return;
    }
    const data = {
      name: name.trim(),
      email: email.trim(),
      description: description.trim(),
    };
    await postData(data);
  };

  return (
    <div className={bgMode ? styles.inquiries_bg_mode : styles.inquiries}>
      <div className="container">
        <div className={styles.inquiries_general_block}>
          <div className={styles.inquiries_left}>
            <h6>{t("inquiries.title")}</h6>
            <span>{t("inquiries.description")}</span>
            {!bgMode && <img loading="lazy" src={inquiriesHorse} alt="" />}
          </div>
          <div className={styles.inquiries_right}>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder={t("inquiries.name")}
                value={name}
                onChange={e => setName(e.target.value)}
                required
              />
              <input
                type="email"
                name="email"
                placeholder={t("inquiries.email")}
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />

              <textarea
                rows="4"
                cols="50"
                type="text"
                name="description"
                placeholder={t("inquiries.message")}
                value={description}
                onChange={e => setDescription(e.target.value)}
                required
              />

              <button
                className={styles.button_send}
                disabled={!check}
                type="submit">
                {t("inquiries.button")}
              </button>
              <div className={styles.check}>
                <label
                  className={bgMode && `${styles.labelBlack}`}
                  htmlFor="check">
                  {t("inquiries.label")}
                </label>
                <input
                  id="check"
                  type="checkbox"
                  className={bgMode && "check_bg"}
                  value={check}
                  onChange={() => setCheck(!check)}
                />
                <span
                  className={
                    bgMode ? `${styles.checkmarkBg}` : `${styles.checkmark}`
                  }></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inquiries;
