import React from "react";
import BlogList from "../../componentes/blog/blogCard/BlogList";

const Blog = () => {
  return (
    <>
      <BlogList />
    </>
  );
};

export default Blog;
