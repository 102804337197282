import React from "react";
import { useTranslation } from "react-i18next";

// import styles
import styles from "./Mission.module.css";

const Mission = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={styles.mission}>
        <h3>{t("missions.title")}</h3>
        <p>{t("missions.description")}</p>
      </div>
    </div>
  );
};

export default Mission;
